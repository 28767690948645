export function displayName(user: {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  username?: string;
}): string {
  return user.fullName || `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.username || '';
}

interface Data {
  id: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  username?: string;
}

export function toOption<TData extends Data>(data: TData) {
  return {
    value: data.id,
    label: displayName(data),
    data,
  };
}
